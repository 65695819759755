.ol-zoom {
  top: 2em !important;
  left: 2em !important;
}

.ol-zoomslider {
  top: 8em !important;
  left: 2em !important;
}

.ol-control {
  padding: 0 !important;
}

.ol-control button {
  width: 2em !important;
  height: 2em !important;
}